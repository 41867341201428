import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Step} from 'carbon-components-angular/progress-indicator';
import {AuthenticationService} from "../../services/authentication.service";
import {ResearchProjectDto} from "../../shared/generated/model/research-project-dto";
import {ResearchProjectStatusTypeEnum} from "../../shared/generated/enum/research-project-status-type-enum";
import {
    ResearchProjectStatusTypeIdToDisplayNameMap
} from "../../shared/models/enums/research-project-status-type.enum.util";
import {UserDto} from "../../shared/generated/model/user-dto";
import {RoleEnum} from "../../shared/generated/enum/role-enum";
import {BasicConfigurableModalComponent} from "../basic/basic-configurable-modal/basic-configurable-modal.component";
import {FormPage} from "../basic/shared/basic-form/basic-form.component";
import {MatDialog} from "@angular/material/dialog";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";

@Component({
    selector: 'castateparksscp-research-permit-public-form-progress-indicator',
    templateUrl: './research-permit-public-form-progress-indicator.component.html',
    styleUrl: './research-permit-public-form-progress-indicator.component.css'
})
export class ResearchPermitPublicFormProgressIndicatorComponent implements OnInit, OnChanges {
    @Input() current: number = 0;
    @Input() researchProjectID: number = 0;
    @Input() researchProject: ResearchProjectDto;
    @Output() stepSelectedEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() statusChangeEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() navigateToApprovePermitEvent: EventEmitter<string> = new EventEmitter<string>();

    static readonly REVIEW_AND_SUBMIT_STEP_INDEX: number = 5;
    skeleton: boolean = false;
    steps: Array<Step> = [
        {
            label: 'Research Team',
        },
        {
            label: 'Project Information',
        },
        {
            label: 'Study Areas',
        },
        {
            label: 'Data Collection',
        },
        {
            label: 'Additional Docs',
        },
        {
            label: 'Review and Submit',
            disabled: true
        },
    ];
    orientation: string = 'vertical';
    spacing: string = "default";

    currentUser: UserDto;
    restrictedStatuses = [
        ResearchProjectStatusTypeEnum.ActivePermit,
        ResearchProjectStatusTypeEnum.Expired,
        ResearchProjectStatusTypeEnum.Rejected,
        ResearchProjectStatusTypeEnum.Withdrawn,
    ];

    constructor(
        public authenticationService: AuthenticationService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public dialog: MatDialog,
        public researchProjectService: ResearchProjectService,
        public route: ActivatedRoute
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['researchProject']) {
            this.researchProject = changes['researchProject'].currentValue;
            if (this.shouldShowInternalReviewStep() && this.steps.length < 7)
             {
                    this.steps.push({
                        label: 'Internal Review',
                    })
            } else if (this.steps.length == 7) {
                this.steps = this.steps.slice(0, 7);
            }

            this.steps[ResearchPermitPublicFormProgressIndicatorComponent.REVIEW_AND_SUBMIT_STEP_INDEX].disabled =
                this.determineReviewAndSubmitStepDisableState();
            this.steps.map((step, index) => {
                if (index != 6) step.complete = this.researchProject
                    && !this.researchProject?.IncompleteSectionNumbers?.includes(index)
            });

            if (!this.researchProject) return;
            let viewableByPublicUser = this.currentUser.Role.RoleID == RoleEnum.Public && (this.researchProject.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher ||
                this.researchProject.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft);
            let viewableBySysAdmin = this.currentUser.Role.RoleID == RoleEnum.SystemAdmin && !this.restrictedStatuses.includes(this.researchProject.ResearchProjectStatusTypeID);
            let viewableByTechnicalReviewer = this.researchProject?.AssignedTechnicalReviewerUserID == this.currentUser.UserID && !this.restrictedStatuses.includes(this.researchProject.ResearchProjectStatusTypeID);
            this.steps.forEach((x, i) => {
                if (viewableByPublicUser || viewableBySysAdmin || viewableByTechnicalReviewer) x.disabled = false;
                else x.disabled = i < ResearchPermitPublicFormProgressIndicatorComponent.REVIEW_AND_SUBMIT_STEP_INDEX;

                if (i == ResearchPermitPublicFormProgressIndicatorComponent.REVIEW_AND_SUBMIT_STEP_INDEX) {
                    x.label = this.researchProject.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ActivePermit ||
                    this.researchProject.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Expired ? "Permit Summary" : "Application Summary";
                }
            })
        }
    }

    private shouldShowInternalReviewStep() {
        if (!this.researchProject) return false;
        return this.currentUser?.Role?.RoleID != RoleEnum.Public
            && (this.researchProject?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Draft)
            && !(this.researchProject?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Withdrawn
                && this.authenticationService.doesCurrentUserHaveOneOfTheseRoles([RoleEnum.DistrictStaff]))
    }

    determineReviewAndSubmitStepDisableState() {
        if (!this.researchProject) return false;
        if (!this.researchProject.IncompleteSectionNumbers) return false;
        return !!this.researchProject.IncompleteSectionNumbers.filter( x =>
            x != ResearchPermitPublicFormProgressIndicatorComponent.REVIEW_AND_SUBMIT_STEP_INDEX).length;
    }

    ngOnInit() {
        this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.cdr.markForCheck();
            });
    }

    stepSelected(event: any) {
        this.stepSelectedEvent.emit(`${this.stepIndexToRouteMap.get(event.index)}${this.researchProjectID}`)
    }

    stepIndexToRouteMap = new Map<number, string>([
        [0,`/public/research-teams/`],
        [1,`/public/research-projects/`],
        [2,`/public/research-project-study-areas/`],
        [3,`/public/research-project-data-collection/`],
        [4,`/public/research-project-documents/`],
        [5,`/public/research-project-review-and-submit/`],
        [6,`/internal-review/`],
    ])

    getResearchProjectStatus() {
        if (!this.researchProject?.ResearchProjectStatusTypeID) return "";
        return ResearchProjectStatusTypeIdToDisplayNameMap.get(this.researchProject?.ResearchProjectStatusTypeID);
    }

    sidebarActionButtonsAvailable() {
        if (!this.researchProject) return false;
        if (this.restrictedStatuses.includes(this.researchProject.ResearchProjectStatusTypeID)) return false;
        if (this.researchProject.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft) return false;
        if (this.currentUser?.Role?.RoleID == RoleEnum.TechnicalReviewer)
            return this.currentUser.UserID == this.researchProject.AssignedTechnicalReviewerUserID;
        return this.currentUser?.Role?.RoleID == RoleEnum.SystemAdmin;
    }

    cannotSendBackToResearcher() {
        return this.researchProject?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.InReview;
    }

    navigateToApproveSection() {
        if (this.route.snapshot.url.some(x => x.path == "internal-review")) {
            this.router.navigate([`/internal-review/${this.researchProjectID}`], {fragment: 'permit-approval', skipLocationChange: true}).then(() => {
                this.navigateToApprovePermitEvent.emit();
            });
            return;
        }
        this.router.navigate([`/internal-review/${this.researchProjectID}`], {fragment: 'permit-approval'});
    }

    returnToResearcher() {
        let dialogRef = this.dialog.open(BasicConfigurableModalComponent, {
            maxWidth: 600,
            data: {
                ID: this.researchProjectID,
                dto: {
                    DPR65ASubmission: null,
                    DPR65BSubmission: null,
                    AdditionalDocumentation: null,
                    OtherInformationOrChanges: null,
                    AdditionalRequestedMessage: null,
                },
                header: 'Send Back to Researcher',
                showActions: true,
                editMode: true,
                formPage: {
                    dtoName: 'additional required information',
                    elements: [
                        {
                            class: 'g-col-7',
                            name: 'DPR65ASubmission',
                            label: 'DPR65A Form Submission',
                            type: 'checkbox',
                        },
                        {
                            class: 'g-col-7',
                            name: 'DPR65BSubmission',
                            label: 'DPR65B Form Submission',
                            type: 'checkbox',
                        },
                        {
                            class: 'g-col-7',
                            name: 'AdditionalDocumentation',
                            label: 'Additional documentation requested/Missing documents',
                            type: 'checkbox',
                        },
                        {
                            class: 'g-col-7',
                            name: 'OtherInformationOrChanges',
                            label: 'Other information or changes requested',
                            type: 'checkbox',
                        },
                        {
                            class: 'g-col-12',
                            name: 'AdditionalRequestedMessage',
                            label: 'Additional Information',
                            type: 'textArea',
                        },
                    ],
                    createUpsertDto: (dto) => {
                        return dto;
                    },
                    put: (id, dto) => {
                        return this.researchProjectService.researchProjectsResearchProjectIDSendBackToReviewerPut(id, dto)
                    },
                } as FormPage,
                saveButtonLabel: 'Submit'
            }
        });

        if (dialogRef) {
            dialogRef.afterClosed().subscribe((response) => {
                if (response.submit) {
                    this.statusChangeEvent.emit();
                }
            });
        }
    }

    reject() {
        let dialogRef = this.dialog.open(BasicConfigurableModalComponent, {
            maxWidth: 600,
            data: {
                ID: this.researchProjectID,
                dto: {
                    RejectionMessage: null
                },
                header: 'Reject Permit',
                showActions: true,
                editMode: true,
                saveButtonLabel: 'Reject',
                saveButtonClass: 'danger',
                formPage: {
                    dtoName: 'reject permit',
                    elements: [
                        {
                            class: 'g-col-12',
                            name: 'RejectionMessage',
                            label: 'Rejection Reason',
                            type: 'textArea',
                            required: true
                        },
                    ],
                    createUpsertDto: (dto) => {
                        return dto;
                    },
                    put: (id, dto) => {
                        return this.researchProjectService.researchProjectsResearchProjectIDRejectPut(id, dto)
                    },
                } as FormPage
            }
        });

        if (dialogRef) {
            dialogRef.afterClosed().subscribe((response) => {
                if (response.submit) {
                    this.statusChangeEvent.emit();
                }
            });
        }
    }

    isRejectButtonDisabled() {
        return this.researchProject?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.InReview
            && this.researchProject?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.AwaitingAnnualReport;
    }
}
